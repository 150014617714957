@import 'src/scss/mixins';

.container {
  display: flex;
  flex-direction: column;
}

.table {
  border: 1px solid #dcdcdc;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  background-color: white;

  th {
    background: #f9fafc;
  }

  td {
    background-color: transparent;
  }
}

.wrapper {
  max-width: 100%;
  margin-right: 1.5rem;
  overflow-x: auto;
  position: relative;

  table {
    max-width: 100%;
    overflow: hidden;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 6px;
    border: 1px solid #dcdcdc;

    thead tr {
      background-color: #f9fafc;
    }

    thead th > div {
      display: flex;
      gap: 14px;
      flex-direction: column;
    }

    tbody tr {
      background-color: white;
    }

    tbody tr:hover {
      background-color: #f3faf5;
    }

    tr:last-child td {
      border: none;
    }

    th button,
    th button svg {
      height: 10px;
    }
  }

  th {
    padding: 1rem !important;
    color: #78858b;
    font-weight: 400;
    font-size: 0.85rem;
    background: #f9fafc;
  }

  td {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  th button {
    background: transparent;
    border: none;
  }

  a {
    color: var(--text-default-color);
    text-decoration: none;
  }

  a:hover {
    color: var(--text-default-color);
  }
}

.search {
  input {
    width: 365px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headLoader {
  position: absolute;
  left: 50%;
}

.filterInput {
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  background: #fff;
  width: 100%;
  padding: 8px;
  height: 34px;
  outline: none;

  &:hover {
    border-color: var(--text-default-color);
  }

  &:focus {
    border-color: var(--text-default-color);
  }
}

.paginationWrapper {
  margin: 24px 0;
}

.paginationWrapper > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 48px 0 0;

  select {
    border-radius: 4px;
    border: 1px solid #dcdcdc;
    background: #fff;
    height: 40px;
    margin-left: 0.5rem;
  }
}

.actions {
  display: none;
}

.paginationActions {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11px;

  span {
    width: fit-content;
  }

  select {
    width: 40px;
    height: 40px;
    appearance: none;
    text-align-last: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }
}

.align-right {
  margin-left: auto;
}

.align-left {
  margin-left: 0;
}

.columnLabel {
  margin-bottom: 0;
  white-space: nowrap;
}

.columnRow {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;

  & div {
    display: flex;
    flex-direction: column;
  }

  button {
    display: flex;

    svg:hover {
      fill: #bababa;
    }
  }

  .sort__button {
    margin-top: 0.125rem;
  }
}

.selectLabel {
  margin-right: 10px;
  margin-bottom: 0;
}

.tablePaginationActions {
  display: flex;
  gap: 8px;

  & li:last-of-type {
    button:hover {
      background-color: transparent;
    }
  }

  & li:first-of-type {
    button:hover {
      background-color: transparent;
    }
  }

  & li:not(:first-of-type):not(:last-of-type) {
    button.Mui-selected {
      outline: none;
      border-radius: 4px;
      border: 1px solid var(--text-default-color);
    }
  }

  & li:focus {
    border-radius: 4px;
    border: 1px solid var(--text-default-color);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.withInput {
  height: 100px;
  &__cell {
    height: 68.39px;
  }
}

.paginationPageInput {
  width: 40px;
  height: 40px;
  padding: 11px 0px;
  border-radius: 4px;
  text-align: center;
  outline: none;

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  border: 1px solid #dcdcdc;

  &:hover {
    border-color: var(--text-default-color);
  }

  &:active {
    border-color: var(--text-default-color);
  }

  &:focus {
    border-color: var(--text-default-color);
  }
}

.empty__label {
  text-align: center;
  font-weight: 600;
  color: #a7a7a7;
}

.no__results {
  color: #a7a7a7;
  text-align: center;
  padding: 1rem;
}

.select {
  padding: 8px 16px;
}

.select:hover {
  outline: solid 1px var(--text-default-color);
}

.containerCheckbox {
  display: flex;
  justify-content: flex-end;
}

.tableCheckbox {
  width: 20px;
  height: 20px;
  box-shadow: none;
  border: 1px solid var(--count-dark);
  position: absolute;
  appearance: none;
  top: 62px;
  cursor: pointer;

  &:checked {
    background-color: var(--text-default-color);
    border: var(--text-default-color);
  }

  &:checked::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 6px;
    width: 6px;
    height: 12px;
    border: solid var(--bg-light);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  @include for-tablet {
    display: none;
  }
}

@media (max-width: 768px) {
  .align-right {
    margin-left: 0;
    margin-top: 28px;
  }
  .align-left {
    margin-top: 28px;
  }
}

@media (max-width: 576px) {
  .paginationActions {
    flex-wrap: wrap;
  }

  .tablePaginationActions {
    margin-top: 28px;
    justify-content: center;
    width: 100%;
  }

  .paginationWrapper {
    padding-bottom: 28px;
  }
}
